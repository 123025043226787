@import '../../assets/scss/_imports.scss';

.info-icon {
	position: relative;
	display: inline-block;
	cursor: pointer;

	&-text {
		display: none;
		position: absolute;
		background-color: $white;
		z-index: 100;
		min-width: 200px;
		max-width: 300px;
		word-wrap: break-word;
		top: 0px;
		left: calc(100% + 5px);
		border-radius: 5px;
		padding: 10px;
		border: solid $black 1px;
	}

	&:hover {
		.info-icon-text {
			display: inline-block;
		}
	}
}