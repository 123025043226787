@import "assets/scss/_imports.scss";

.displaySeats {
	@include pod-item;
	display: grid;
	grid-template-columns: auto 1fr;
	justify-content: center;
	align-items: center;
	column-gap: 30px;

	@include medium {
		grid-template-columns: 100%;
		justify-items: center;
	}

	@include small {
		grid-template-columns: 100%;
		justify-items: center;
		overflow: hidden;
	}

	h3 {
		margin-bottom: 0;

		@include medium {
			margin-bottom: 1rem;
		}

		@include small {
			margin-bottom: 1rem;
		}
	}

	.popup-container {
		position: relative;
		display: inline-block;
		margin-left: 5px;

		.info-icon {
			color: $primary;
		}

		.popup {
			visibility: hidden;
			width: 200px;
			background-color: #fff;
			text-align: center;
			border-radius: 5px;
			border: solid 1px $grey;
			padding: 20px;
			position: absolute;
			z-index: 1;
			top: 125%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 9999;

			p:first-of-type {
				margin-bottom: 20px;
			}
		}

		&:hover .popup {
			visibility: visible;
		}
	}

	&-amount {
		width: 100%;
		border: solid 2px $grey;
		height: 60px;
		border-radius: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;

		&-bar {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: $secondary;
			z-index: 1;
		}

		p {
			z-index: 2;
			font-weight: 600;
			font-size: 16px;
		}
	}
}
