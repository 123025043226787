@import "assets/scss/_imports.scss";

.list-accordion {
	position: relative;
	margin-top: 40px;

	.accordionToggle {
		background: none;
		border: none;
		position: relative;
		padding: 0px 25px 0px 0px;
		color: $primary;
		position: absolute;
		right: 0;
		top: -20px;


		.button-arrow-icon {
			top: -1px;
			right: 0;
			transform: translate(-50%, -50%) rotate(90deg);
			transition: all .3s ease;

			&.toggled {
				transform: translate(-50%, -50%) rotate(270deg);
			}
		}

		&:hover {
			color: $primary;

			.button-arrow-icon {
				top: 2px;
			}
		}
	}

	&-panel {
		overflow: hidden;
		transform: translateY(20px);
		height: 0;
	}

	.submodule {
		display: grid;
		grid-template-columns: 6rem auto;
		border: solid 1px #ddd;
		border-radius: 1rem;
		margin-bottom: 1rem;
		min-height: 100px;
		align-content: center;
		overflow: hidden;

		&-progress {
			display: grid;
			align-content: center;
			align-items: center;
			justify-content: center;
			border-right: solid 1px #ddd;
			min-height: 100px;
			grid-template-rows: 60px 20px;

			.small-text {
				margin-top: 5px;
				text-align: center;
			}

			&.locked, &.soft-locked {
				height: 100%;
				justify-content: center;

				.locked-icon {
					height: 60px;
					width: 60px;
					border: solid 5px $primary;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					justify-self: center;
					align-items: center;

					svg {
						color: $primary;
						height: 22px;
					}
				}
			}

			&.locked {
				background-color: $grey;
			}
		}

		&-details {
			display:grid;
			align-self: center;

			&-text {
				display: flex;
				margin: 0 1rem;
				align-self: center;
				grid-gap: 5px;

				h4 {
					margin: 0;
				}
			}

			&-llj {
				margin: 0.4rem 1rem 0;
			}
		}
	}

	.extra {
		display: grid;
		grid-template-columns: 6rem 1fr auto;
		border: solid 1px #ddd;
		border-radius: 1rem;
		margin-bottom: 1rem;
		min-height: 100px;
		align-content: center;
		overflow: hidden;

		&-icon {
			display: grid;
			align-content: center;
			align-items: center;
			justify-content: center;
			border-right: solid 1px #ddd;
			min-height: 100px;
			background-color: $primary;

			svg {
				color: $white;
			}

			.up-3 {
				transform: translateY(-3px);
			}
		}

		&-text {
			padding: 1.6rem 1rem;
			align-content: center;

			&-alone {
				margin: 0;
			}
		}

		&-link {
			align-self: center;
			padding-right: 1rem;
		}
	}
}
