@import "assets/scss/_imports.scss";

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		/* Quick and dirty way to hide the background content */
		&.clear {
			background: #888;
		}
	}

	&-header {
		background-color: $primary;
		padding: 1.5rem 1.5rem 1rem 1.5rem;
		color: $white;
		position: relative;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
		position: relative;
		overflow: hidden;

		&-image {
			position: absolute;
			max-height: 80%;
			top: 0;
			right: 4px;
			opacity: 0.2;
			z-index: 2;
		}

		h1 {
			position: relative;
			z-index: 2;
			color: $white;
			margin-bottom: .5rem;
		}

		&-grad {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: linear-gradient(90deg, rgba(0,0,0,.5) 0%, $primary 50%, $primary 100%);
		}
	}

	&-container {
		background: white;
		border-radius: 1rem;
		position: relative;
		width: 800px;
		z-index: 1001;

		@include small {
			max-width: 92%;
		}

		&.yesno {
			width: 400px;

			.modal-content {
				min-height: unset;

				.modal-buttons {
					display: flex;
					justify-content: space-around;
				}
			}
		}
	}

	&-content {
		border: none;
		min-height: 100px;
		padding: 1.5rem 1.5rem 1rem 1.5rem;
		display: grid;
		grid-template-rows: 1fr auto;
		border-radius: 1rem;

		@include small {
			max-height: 80vh;
			overflow: auto;
		}

		&.nogap {
			min-height: 0;
		}

		.modal-buttons {
			justify-self: flex-end;
		}
	}

	&-welcome-content {
		border: none;
		min-height: 200px;
		padding: 1.5rem 1.5rem 1rem 1.5rem;

		p {
			margin-bottom: 1rem;
		}

		.modal-buttons {
			margin-top: 1.5rem;
			justify-self: flex-end;
		}
	}

	&-close {
		position: absolute;
		top: 50%;
		right: -6px;
		transform: translate(-100%, -50%);
		background: none;
		border: none;
		font-size: 1.5rem;
		cursor: pointer;
		color: $white;
		z-index: 1001;
	}

	&-buttons {
		display: flex;
		justify-content: flex-end;

		@include small {
			display: grid;
			grid-row-gap: 10px;
			justify-items: flex-end;
		}

		& .button {
			margin-left: 10px;
		}
	}
}

.question-buttons {
	.button:first-of-type {
		margin-right: 10px;
	}
}

//Message Modal

.p-dialog-mask {
	background-color: $white;
}

.confirmation-message {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
